import { ScrollCaptain } from 'scroll-captain';
import './graphic-animation.scss';

class GraphicAnimation {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-graphicanimation',
            arrivalBgValue: 'arrival-bg',
            arrivalValue: 'arrival',
            leftToRightValue: 'driving-left-to-right',
            wheelValue: 'wheel',
            perspectiveWheelValue: 'perspective-wheel',
            breakpoint: 1023
        };

        // Merge settings
        this.settings = Object.assign({}, defaults, options);
        
        // DOM Elements
        this.$root = element;

        // Options
        this.animation = null;
        this.breakpoint = window.matchMedia(`(max-width: ${this.settings.breakpoint}px)`);
        this.breakpointActive = this.breakpoint.matches;
    }

    initialize () { 
        this.loadAnimation();
        this.setEvents();
    }

    // Configure and load animations with ScrollCaptain
    loadAnimation () {
        this.animation = new ScrollCaptain(this.$root, {
            initAttr: this.settings.initAttr,
            animateArrivalBg: {
                targetSuffix: this.settings.arrivalBgValue,
                onscroll: true,
                duration: this.breakpointActive ? 0 : 0.2,
                translateX: window.reducedMotionActive ? null : { 0: -20, 50: 0}
            },
            animateArrival: {
                targetSuffix: this.settings.arrivalValue,
                onscroll: true,
                duration: this.breakpointActive ? 0 : 0.2,
                translateX: window.reducedMotionActive ? null : { 0: this.breakpointActive ? 100 : 200, 35: 20, 50: 0 },
                translateY: window.reducedMotionActive ? null : { 0: -20, 35: -5, 50: 0 },
                scale: window.reducedMotionActive ? null : { 0: 0.8, 35: 0.98, 50: 1 }
            },
            animateLeftToRight: {
                targetSuffix: this.settings.leftToRightValue,
                onscroll: true,
                duration: this.breakpointActive ? 0 : 0.2,
                translateX: window.reducedMotionActive ? null : { 0: this.breakpointActive ? -100 : -300, 100: this.breakpointActive ? 100 : 300 }
            },
            animateWheel: {
                targetSuffix: this.settings.wheelValue,
                onscroll: true,
                duration: this.breakpointActive ? 0 : 0.2,
                rotate: window.reducedMotionActive ? null : { 0: 0, 100: 650 }
            },
            animatePerspectiveWheel: {
                targetSuffix: this.settings.perspectiveWheelValue,
                onscroll: true,
                duration: this.breakpointActive ? 0 : 0.2,
                rotate: window.reducedMotionActive ? null : { 0: 0, 40: -400, 50: -430 }
            },
            updateOnResize: () => {
                if (this.breakpoint.matches !== this.breakpointActive) {
                    this.breakpointActive = this.breakpoint.matches;
                    this.updateAnimation(false);
                }
            }
        });        
    }

    updateAnimation (updateRequired) {
        // Arrival    
        this.animation.defaultOptions.animateArrivalBg.duration = this.breakpointActive ? 0 : 0.2;
        this.animation.defaultOptions.animateArrivalBg.translateX = window.reducedMotionActive ? null :{ 0: -20, 50: 0};
        this.animation.defaultOptions.animateArrival.duration = this.breakpointActive ? 0 : 0.2;
        this.animation.defaultOptions.animateArrival.translateX = window.reducedMotionActive ? null : { 0: this.breakpointActive ? 100 : 200, 35: 20, 50: 0 };
        this.animation.defaultOptions.animateArrival.translateY = window.reducedMotionActive ? null : { 0: -20, 35: -5, 50: 0 };
        this.animation.defaultOptions.animateArrival.scale = window.reducedMotionActive ? null : { 0: 0.8, 35: 0.98, 50: 1 };
        
        // Driving from left to right
        this.animation.defaultOptions.animateLeftToRight.duration = this.breakpointActive ? 0 : 0.2;
        this.animation.defaultOptions.animateLeftToRight.translateX = window.reducedMotionActive ? null : { 0: this.breakpointActive ? -100 : -300, 100: this.breakpointActive ? 100 : 300 };

        if (updateRequired) {
            this.animation.update();
        }
    }

    setEvents () {
        window.addEventListener('motionPreferenceChanged', () => {
            this.updateAnimation(true);
        });
    }
}

export { GraphicAnimation };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-graphicanimation="root"]');
        $$roots.forEach($root => {
            const $rootAPI = new GraphicAnimation($root);
            $rootAPI.initialize();
        });
    }
});
